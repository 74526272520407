import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { getStage } from './index';
import {
  AWS_RUM_APPLICATION_ID,
  AWS_RUM_ENDPOINT,
  AWS_RUM_GUEST_ROLE_ARN,
  AWS_RUM_IDENTITY_POOL_ID,
} from './Constants';

export class AwsRumClient {
  // @ts-ignore
  private awsRum : AwsRum;

  private static rumClient: AwsRumClient;

  private constructor() {
    this.initAwsRum();
  }

  private initAwsRum() {
    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        sessionEventLimit: 0,
        guestRoleArn: AWS_RUM_GUEST_ROLE_ARN[getStage()],
        identityPoolId: AWS_RUM_IDENTITY_POOL_ID[getStage()],
        endpoint: AWS_RUM_ENDPOINT[getStage()],
        telemetries: ['performance', 'errors', 'http', 'interaction'],
        allowCookies: true,
        enableXRay: true,
      };
      const APPLICATION_ID = AWS_RUM_APPLICATION_ID[getStage()];
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-east-1';
      this.awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );
    } catch (error) {
      console.error(`CloudWatch RUM web client initialization failed with error: ${error}`);
    }
  }

  public static initializeRumClient() {
    this.rumClient = new AwsRumClient();
  }

  public static getRumClient(): AwsRumClient {
    return this.rumClient;
  }

  public recordRumEvent(eventType:string, eventObject: Record<string, unknown>) {
    if (this.awsRum) {
      this.awsRum.recordEvent(eventType, eventObject);
    } else {
      console.error('CloudWatch Rum recordEvent awsRum is not initialized');
    }
  }
}
